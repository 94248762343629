import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Box from 'gatsby-theme-signalwerk/src/components/Box';
export const _frontmatter = {
  "title": "Adana platen machine 5 × 3 Inch",
  "author": "Stefan Huber",
  "date": "2020-05-01",
  "layout": "post",
  "draft": false,
  "hideInMenu": false,
  "path": "/posts/adana/",
  "tags": ["TagOne", "TagTwo"],
  "description": "I got my first secondhand Adana platen machine"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "my-first-platen-machine"
    }}>{`My first platen machine`}</h2>
    <p>{`This weeke I found a quite affordable letterpress printing machine on a secondhand online market. The price was quite good so I decided to order the tiny machine. It's a 5 × 3 Inch platen machine of the brand «Adana». You can even get replacement parts from the `}<a parentName="p" {...{
        "href": "http://caslon.co.uk/"
      }}>{`compoany`}</a>{` who made it.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/839231c877fd6d31e861548222929e35/d9c39/ricardo-add-01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBP/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHouBTVn//EABkQAAMBAQEAAAAAAAAAAAAAAAABAhESIf/aAAgBAQABBQJvBYzC56U+I//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAQADAAAAAAAAAAAAAAAAAAEQIFH/2gAIAQEABj8CoGR//8QAGhABAAMAAwAAAAAAAAAAAAAAAQARMRAhUf/aAAgBAQABPyEd57EHTcpBQcgpaAVx/9oADAMBAAIAAwAAABAbH//EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAEDAQE/EIf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxCn/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARMSFBUZH/2gAIAQEAAT8QWsrl8PUMUcLsz7GzI9CLT2EIoCirjwz/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ricardo add 01",
            "title": "ricardo add 01",
            "src": "/static/839231c877fd6d31e861548222929e35/d9c39/ricardo-add-01.jpg",
            "srcSet": ["/static/839231c877fd6d31e861548222929e35/20e5d/ricardo-add-01.jpg 450w", "/static/839231c877fd6d31e861548222929e35/8e1fc/ricardo-add-01.jpg 900w", "/static/839231c877fd6d31e861548222929e35/d9c39/ricardo-add-01.jpg 1800w"],
            "sizes": "(max-width: 1800px) 100vw, 1800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e5c4ca6258128db3413ec58e8c4114b7/d9c39/ricardo-add-02.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBP/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHVKdBAn//EABkQAAEFAAAAAAAAAAAAAAAAAAEAAhESIP/aAAgBAQABBQIuAV5z/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxAAAwEAAAAAAAAAAAAAAAAAACAhMf/aAAgBAQAGPwKmL//EABoQAAICAwAAAAAAAAAAAAAAAAABETFBYcH/2gAIAQEAAT8ht0CgsidnTA7P/9oADAMBAAIAAwAAABBgL//EABYRAAMAAAAAAAAAAAAAAAAAABARIf/aAAgBAwEBPxCIf//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPxClf//EABkQAQADAQEAAAAAAAAAAAAAAAEAESFBUf/aAAgBAQABPxAEQHvCVSCcb96R3F1eCCkSxlACsJmP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ricardo add 02",
            "title": "ricardo add 02",
            "src": "/static/e5c4ca6258128db3413ec58e8c4114b7/d9c39/ricardo-add-02.jpg",
            "srcSet": ["/static/e5c4ca6258128db3413ec58e8c4114b7/20e5d/ricardo-add-02.jpg 450w", "/static/e5c4ca6258128db3413ec58e8c4114b7/8e1fc/ricardo-add-02.jpg 900w", "/static/e5c4ca6258128db3413ec58e8c4114b7/d9c39/ricardo-add-02.jpg 1800w"],
            "sizes": "(max-width: 1800px) 100vw, 1800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/864201d43c714012cc562a796f104080/d9c39/ricardo-add-03.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDAf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAGjTxLiDf/EABcQAQADAAAAAAAAAAAAAAAAAAEAEiD/2gAIAQEAAQUCJYXH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFhABAQEAAAAAAAAAAAAAAAAAAQAg/9oACAEBAAY/ApDP/8QAGxABAAICAwAAAAAAAAAAAAAAAQARMXEQIWH/2gAIAQEAAT8h7ICG4OC68mlOMYhP/9oADAMBAAIAAwAAABD0z//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABsQAQEBAAIDAAAAAAAAAAAAAAERADFxIUFR/9oACAEBAAE/ELYBeV4z9uNWB6wTgPg73NaIDomSl3//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ricardo add 03",
            "title": "ricardo add 03",
            "src": "/static/864201d43c714012cc562a796f104080/d9c39/ricardo-add-03.jpg",
            "srcSet": ["/static/864201d43c714012cc562a796f104080/20e5d/ricardo-add-03.jpg 450w", "/static/864201d43c714012cc562a796f104080/8e1fc/ricardo-add-03.jpg 900w", "/static/864201d43c714012cc562a796f104080/d9c39/ricardo-add-03.jpg 1800w"],
            "sizes": "(max-width: 1800px) 100vw, 1800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      